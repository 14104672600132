<template>
  <div style="margin-bottom: 300px">
    <v-row class="text-center mt-2">
      <v-col cols="12">
        <v-row>
          <v-col cols="2" md="4"> </v-col>
          <v-col cols="8" md="4">
            <h1 style="color: white; border: solid 1px">ตู้เซฟของฉัน</h1>
          </v-col>
        </v-row>

        <v-card
          class="mx-auto d-flex align-center justify-center pa-8 mt-4"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-row>
            <v-col cols="12" md="12" sm="12" v-if="lotteryDate.length">
              <span style="font-size: 20px">เลือกงวด</span>
              <v-select
                v-model="selectedDate"
                :items="lotteryDate"
                item-text="date"
                item-value="lotteryDate"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-tabs grow v-model="tabs">
                <v-tab>ตู้เซฟ</v-tab>
                <v-tab>ล็อตเตอรี่ที่ถูกรางวัล</v-tab>
              </v-tabs>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-row v-if="!itemsLottery.length">
                    <v-col>
                      <v-card class="gradient-button pa-4">
                        <span style="font-size: 20px"
                          >ท่านยังไม่มี <br />
                          รายการสั่งซื้อที่สำเร็จ</span
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      v-for="(lotto, index) in itemsLottery"
                      :key="index"
                    >
                      <v-card
                        style="font-size: 16px; border: 1px solid #4ca3ad"
                        class="py-4 px-4 mb-4"
                        :search="search"
                        :style="
                          lotto.isWin
                            ? 'background: linear-gradient(#dfc25e 0%, #eedfa9 100%)'
                            : ''
                        "
                      >
                        <div
                          class="my-4 py-2 text-center"
                          style="padding: 5px; border: 3px solid #4ca3ad"
                        >
                          สถานะ

                          <span v-if="lotto.status == 'PENDING'">
                            <span style="color: #647d87">รอการชำระเงิน</span>
                          </span>
                          <span v-if="lotto.status == 'รอดำเนินการ'">
                            <span style="color: #008080">รอดำเนินการ</span>
                          </span>
                          <span v-if="lotto.status == 'SUCCESS'">
                            <span style="color: green"
                              >อนุมัติ/ยืนยันสลิปถูกต้อง</span
                            >
                          </span>
                          <span v-if="lotto.status == 'มีปัญหา'">
                            <span style="color: red">มีปัญหา</span>
                          </span>
                          <span v-if="lotto.status == 'ยกเลิก'">
                            <span style="color: red">ยกเลิก</span>
                          </span>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            หมายเลขคำสั่งซื้อ
                          </p>
                          <p>{{ lotto.transactionNo }}</p>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            ประจำงวดที่
                          </p>
                          <p>{{ convertDateLottery(lotto.lotteryDate) }}</p>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            วัน - เวลา ที่สั่งซื้อ
                          </p>
                          <p>{{ convertDate(lotto.createdAt) }}</p>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            จำนวนใบที่สั่งซื้อ
                          </p>
                          <p>{{ lotto.lotteryTransaction.length }} ใบ</p>
                        </div>
                        <div class="mb-4 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            ราคารวม
                          </p>
                          <p>{{ lotto.totalPrice }} บาท</p>
                        </div>
                        <v-btn
                          large
                          block
                          class="gradient-button"
                          @click="tranxDetail(lotto.id)"
                        >
                          <span style="color: white">รายละเอียด</span>
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row v-if="!itemsLotteryWins.length">
                    <v-col>
                      <v-card class="gradient-button pa-4">
                        <span style="font-size: 20px"
                          >ท่านยังไม่มี <br />
                          รายการสั่งซื้อที่ถูกรางวัล</span
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      v-for="(lotto, index) in itemsLotteryWins"
                      :key="index"
                    >
                      <v-card
                        style="font-size: 16px; border: 1px solid #4ca3ad"
                        class="py-4 px-4 mb-4"
                        :search="search"
                        :style="
                          lotto.isWin
                            ? 'background: linear-gradient(#dfc25e 0%, #eedfa9 100%)'
                            : ''
                        "
                      >
                        <div
                          class="my-4 py-2 text-center"
                          style="padding: 5px; border: 3px solid #4ca3ad"
                        >
                          สถานะ

                          <span v-if="lotto.status == 'PENDING'">
                            <span style="color: #647d87">รอการชำระเงิน</span>
                          </span>
                          <span v-if="lotto.status == 'รอดำเนินการ'">
                            <span style="color: #008080">รอดำเนินการ</span>
                          </span>
                          <span v-if="lotto.status == 'SUCCESS'">
                            <span style="color: green"
                              >อนุมัติ/ยืนยันสลิปถูกต้อง</span
                            >
                          </span>
                          <span v-if="lotto.status == 'มีปัญหา'">
                            <span style="color: red">มีปัญหา</span>
                          </span>
                          <span v-if="lotto.status == 'ยกเลิก'">
                            <span style="color: red">ยกเลิก</span>
                          </span>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            หมายเลขคำสั่งซื้อ
                          </p>
                          <p>{{ lotto.transactionNo }}</p>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            ประจำงวดที่
                          </p>
                          <p>{{ datefrom }}</p>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            วัน - เวลา ที่สั่งซื้อ
                          </p>
                          <p>{{ convertDate(lotto.createdAt) }}</p>
                        </div>
                        <div class="mb-2 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            จำนวนใบที่สั่งซื้อ
                          </p>
                          <p>{{ lotto.lotteryTransaction.length }} ใบ</p>
                        </div>
                        <div class="mb-4 ml-4">
                          <p
                            style="
                              color: #008080;
                              font-weight: 800;
                              font-size: 18px;
                            "
                          >
                            ราคารวม
                          </p>
                          <p>{{ lotto.totalPrice }} บาท</p>
                        </div>
                        <v-btn
                          large
                          block
                          class="gradient-button"
                          @click="tranxDetail(lotto.id)"
                        >
                          <span style="color: white">รายละเอียด</span>
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogWin"
      width="500"
      height="auto"
      @click:outside="cancelDialogWin()"
      @keydown.esc="cancelDialogWin()"
    >
      <v-card>
        <v-card-title style="background-color: #4ca3ad">
          <v-row>
            <v-col class="text-center"
              ><span style="color: white; font-size: 30px">ยินดีด้วย</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pa-4 text-center">
          <span style="font-size: 24px">
            รายการสั่งซื้อของท่านถูกรางวัล <br />
            ตรวจสอบรายการที่ถูกรางวัล <br />
            คลิกที่ปุ่มด้านล่าง
          </span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row>
            <v-col class="text-center">
              <v-btn class="gradient-button" @click="goToTab2()">
                ดูรายการคำสั่งซื้อที่ถูกรางวัล
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import moment from "moment";

export default {
  components: {},

  name: "BuyLottery",

  data: () => ({
    dialogWin: false,
    dialogLogin: false,

    search: "",
    datefrom: "",

    hidden: false,
    tabs: null,

    userData: [],
    orderThai: [],
    orderWinnings: [],

    selectedDate: null,
    lotteryDate: [],
    itemsLottery: [],
    itemsLotteryWins: [],
  }),

  watch: {
    selectedDate(val) {
      const filterDate = this.orderThai.filter((x) => x.lotteryDate == val);
      const filterDateWins = this.orderWinnings.filter(
        (x) => x.lotteryDate == val
      );
      this.itemsLottery = filterDate;
      this.itemsLotteryWins = filterDateWins;
    },
  },

  async created() {
    const encodedUserLogin = localStorage.getItem("No1User");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }

    await this.getlotterythai();
  },

  methods: {
    cancelDialogWin() {
      this.dialogWin = false;
    },
    goToTab2() {
      this.tabs = 1;
      this.dialogWin = false;
    },
    convertDate(date) {
      return moment(String(date)).format("DD/MM/YYYY HH:mm");
    },
    convertDateLottery(date) {
      return moment(String(date)).format("DD/MM/YYYY");
    },
    async getlotterythai() {
      const auth = {
        headers: { Authorization: `Bearer ${this.userData.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAllByUser/` +
          this.userData.user.id,
        auth
      );
      let getlotto = response.data.data.filter((x) => x.status == "SUCCESS");
      this.orderThai = getlotto;
      this.findDuplicateLotteryDate(getlotto);

      const data = {
        position1: "",
        position2: "",
        position3: "",
        position4: "",
        position5: "",
        position6: "",
        setAmt: "",
        stock: "",
      };
      const responseCheckDate = await this.axios.post(
        `${process.env.VUE_APP_API}/lottery/searchByNumber`,
        data
      );
      const lotteryDate = responseCheckDate.data.data[0].lotteryDate;
      this.orderThai.forEach(async (element) => {
        if (element.isWin) {
          this.orderWinnings.push(element);
          if (element.lotteryDate == lotteryDate) {
            this.dialogWin = true;
          }
        }
      });

      const filterDate = this.orderThai.filter(
        (x) => x.lotteryDate == this.selectedDate
      );
      const filterDateWins = this.orderWinnings.filter(
        (x) => x.lotteryDate == this.selectedDate
      );
      this.itemsLottery = filterDate;
      this.itemsLotteryWins = filterDateWins;
    },
    async findDuplicateLotteryDate(getlotto) {
      const newArray = getlotto.filter((item, index, arr) => {
        const firstIndex = arr.findIndex(
          (el) => el.lotteryDate === item.lotteryDate
        );
        return index === firstIndex;
      });
      console.log(newArray);

      newArray.forEach((element) => {
        const dateObj = new Date(element.lotteryDate);
        const thaiDate = new Intl.DateTimeFormat("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(dateObj);
        this.lotteryDate.push({
          date: thaiDate,
          lotteryDate: element.lotteryDate,
        });
      });
      this.selectedDate = this.lotteryDate[0].lotteryDate;
    },
    tranxDetail(val) {
      localStorage.setItem("transactionDetail", Encode.encode(val));
      this.$router.push("/transactionDetail");
    },
  },
};
</script>

<style scoped>
.gradient-button {
  background: linear-gradient(#4ca3ad 0%, #008080 100%);
  color: white;
}
</style>



